<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              :disabled="isNew"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" disabled @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12" md="12" v-if="address">
            <v-text-field type="text"
                          filled
                          label="Адреса"
                          v-model="address"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="address ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="6">
            <date-component label="Місяць реєстрації"
                            v-model="month"
                            :class_="month ? '' : 'req-star'"
            />
          </v-col>
          <v-col cols="12" md="6">
            <date-component label="Дата для перерахунку"
                            v-model="recalculation_date"
                            :class_="recalculation_date ? '' : 'req-star'"
            />
          </v-col>
          <v-col cols="12" md="12">
            <v-select filled
                      :items="registration_of_changes_section_type"
                          label="Розділ обліку"
                          v-model="changes_section_type"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="changes_section_type ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="12">
            <v-textarea v-model="description"
                        filled hide-details
                        label="Опис зміни"
                        auto-grow rows="2"
                        required :rules="[v => !!v || 'Це поле є обов’язковим']"
                        :class="description ? '' : 'req-star'"
                        color="grey"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea v-model="comments"
                        filled hide-details
                        label="Коментар"
                        auto-grow rows="2"
                        color="grey"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import {
  CREATE_REGISTRATION_OF_CHANGES
} from "@/store/actions/registration_of_changes";

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {registration_of_changes_section_type} from "@/utils/icons"

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'registration_of_changes_modal_delete'

export default {
  name: "HWP_Modal_RegistrationOfChanges",
  mixins: [ModalComponentMixin],
  data() {
    return {

      address: this.item.address,
      changes_section_type: this.item.changes_section_type,
      month: this.item.month,
      description: this.item.description,
      comments: this.item.comments,
      recalculation_date: this.item.recalculation_date,
      flat_id: this.item.flat_id,
      person_hash: this.item.person_hash,
      registration_of_changes_section_type
    }
  },
  methods: {
    closeModal(event, payload=false) {
      this.$emit('closeModal', payload)
      this.itemId = this.item.id
      this.address = this.item.address
      this.changes_section_type = this.item.changes_section_type
      this.month = this.item.month
      this.description = this.item.description
      this.comments = this.item.comments
      this.recalculation_date = this.item.recalculation_date
      this.flat_id = this.item.flat_id
      this.person_hash = this.item.person_hash

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: 'Підтвердіть вилучення реєстрації змін',
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Місяць, дата для перерахунку, розділ обліку та опис - мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        changes_section_type: this.changes_section_type,
        month: this.month,
        description: this.description,
        comments: this.comments,
        recalculation_date: this.recalculation_date,
        flat_id: this.flat_id,
        person_hash: this.person_hash
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_REGISTRATION_OF_CHANGES, payload)
            .then(el => {
              if (el) {
                this.closeModal(null, true)
              }
            })
      } else {
        // payload.id = this.itemId
        // this.$store.dispatch(UPDATE_CHECKER, payload)
        //     .then(el => {
        //       if (el) {
        //         this.closeModal()
        //       }
        //     })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.address = payload.address
              this.changes_section_type = payload.changes_section_type
              this.month = payload.month
              this.description = payload.description
              this.comments = payload.comments
              this.recalculation_date = payload.recalculation_date
              this.flat_id = payload.flat_id
              this.person_hash = payload.person_hash
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  // this.$store.dispatch(DELETE_CHECKER, this.itemId)
                  //   .then(ok => {
                  //     if (ok) {
                  //       this.closeModal()
                  //     }
                  //   })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  }
}
</script>
